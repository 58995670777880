import React from "react";
import modelWithCocktail from "../../images/WaliaModelWithWaliaCocktail.jpg";

const backGroundImageStyle = {
  backgroundImage: `url(${modelWithCocktail})`,
  backgroundSize: "cover",
};

const HomePageItem1 = () => (
  <div
    className="home-page-item home-page-item-1"
    style={backGroundImageStyle}
  ></div>
);

export default HomePageItem1;

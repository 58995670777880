import React from "react";
import { GeoAltFill, Cart } from "react-bootstrap-icons";

const addressInfoStyle = {
  color: "white",
};

const geoAltStyle = {
  color: "red",
};

const blueLinkInfo = {
  color: "cornflowerBlue",
};

export const availableStores = [
  {
    storeName: "Morris Miller Wines & Liquors",
    position: { lat: 38.98423, lng: -77.02696 },
    marker: (
      <a href="https://goo.gl/maps/F5rXvPxwq7LTsAko6" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/F5rXvPxwq7LTsAko6" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 7804 Alaska Ave NW,
        Washington, DC 20012
      </a>
    ),
    info: (
      <a href="https://www.morrismillerdc.com/" style={addressInfoStyle}>
        {" "}
        <Cart size={35} style={blueLinkInfo} />{" "}
      </a>
    ),
  },
  {
    storeName: "Sherr's Wine & Spirits",
    position: { lat: 38.92437, lng: -77.05158 },
    marker: (
      <a href="https://goo.gl/maps/FWF7t8dz58wsDaLR7" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/FWF7t8dz58wsDaLR7" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 2627 Connecticut Ave NW
        Washington, DC 20008
      </a>
    ),
    info: (
      <a href="https://www.sherryswine.com/" style={addressInfoStyle}>
        {" "}
        <Cart size={35} style={blueLinkInfo} />{" "}
      </a>
    ),
  },
  {
    storeName: "Virginia ABC",
    position: { lat: 38.92437, lng: -77.05158 },
    address: (
      <div>
        <a
          href="https://www.abc.virginia.gov/products/cordials/walia-ibex-areke?productSize=0"
          style={blueLinkInfo}
        >
          Purchase online
        </a>
        &nbsp;or call
        <a href="tel:8042134528" style={addressInfoStyle}>
          &nbsp;(804) 213 - 4528
        </a>
      </div>
    ),
    info: (
      <a
        href="https://www.abc.virginia.gov/products/cordials/walia-ibex-areke?productSize=0"
        style={addressInfoStyle}
      >
        {" "}
        <Cart size={35} style={blueLinkInfo} />{" "}
      </a>
    ),
  },
  {
    storeName: "Virginia ABC",
    position: { lat: 38.82469, lng: -77.31549 },
    marker: (
      <a href="https://goo.gl/maps/3fvmCWdrBB5n72N1A" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/3fvmCWdrBB5n72N1A" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
        10685 Braddock Rd Fairfax, VA 22032
      </a>
    ),
    info: (
      <a
        href="https://www.abc.virginia.gov/stores/393"
        style={addressInfoStyle}
      >
        {" "}
        <Cart size={35} style={blueLinkInfo} />{" "}
      </a>
    ),
  },
  {
    storeName: "Virginia ABC",
    position: { lat: 38.81267, lng: -77.1063 },
    marker: (
      <a href="https://goo.gl/maps/kPF7ZBRwRaCZjuYv5" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/kPF7ZBRwRaCZjuYv5" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 4349 Duke St Alexandria, VA
        22304
      </a>
    ),
    info: (
      <a
        href="https://www.abc.virginia.gov/stores/397"
        style={addressInfoStyle}
      >
        {" "}
        <Cart size={35} style={blueLinkInfo} />{" "}
      </a>
    ),
  },
  {
    storeName: "Virginia ABC",
    position: { lat: 38.84782, lng: -77.1206 },
    marker: (
      <a href="https://goo.gl/maps/UCV4P1ZyT97V563M9" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/UCV4P1ZyT97V563M9" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 3556E South Jefferson
        Street Bailey&#8217;s Crossroads, VA 22041-3106
      </a>
    ),
    info: (
      <a
        href="https://www.abc.virginia.gov/stores/294"
        style={addressInfoStyle}
      >
        {" "}
        <Cart size={35} style={blueLinkInfo} />{" "}
      </a>
    ),
  },
];

import React from "react";
import { Tabs, Tab, Jumbotron, Card } from "react-bootstrap";
import WaliaModelDrinking from "../../images/WaliaModelDrinking.jpg";
import WaliaMultipleDrinks from "../../images/WaliaMultipleDrinks.jpg";
import WaliaWithLemonCup from "../../images/WaliaWithLemonCup.jpg";
import WaliaWithIce from "../../images/WaliaWithIce.jpg";
import WaliaWithCola from "../../images/WaliaWithCola.jpg";
import WaliaIbexBitter from "../../images/WaliaIbexBitter.png";
import WaliaYellowDrink from "../../images/WaliaYellowDrink.png";
import WaliaIbexSweetBitter from "../../images/WaliaIbexSweetBitter.png";
import WaliaIbexBlue from "../../images/WaliaIbexBlue.jpg";
import { Helmet } from "react-helmet";

const homeDrinkBackGroundImageStyle = {
  backgroundImage: `url(${WaliaModelDrinking})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center bottom",
  minHeight: "370px",
};

const barDrinkBackGroundImageStyle = {
  backgroundImage: `url(${WaliaMultipleDrinks})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center bottom",
  minHeight: "370px",
};

const CocktailRecipe = () => (
  <div className="page-margin-with-header" style={{}}>
    <BarDrinkSubPage />
    {/* <Tabs fill justify defaultActiveKey="home-drink" id="uncontrolled-tab">
      <Tab eventKey="home-drink" title="Home Drink">
        <HomeDrinkSubPage />
      </Tab>
      <Tab eventKey="bar-drink" title="Bar Drink">
       
      </Tab>
    </Tabs> */}
  </div>
);

const HomeDrinkSubPage = () => (
  <div>
    <Jumbotron style={homeDrinkBackGroundImageStyle}>
      <h1>Walia Ibex Home Drink</h1>
    </Jumbotron>
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <Card>
            <Card.Img variant="top" src={WaliaWithIce} />
            <Card.Title className="cocktail-recipe-title">Shambel</Card.Title>
            <ul className="recipes-list">
              <li>Walia Ibex</li>
              <li>Ice</li>
              <br />
            </ul>
          </Card>
        </div>
        <div className="col-md-4">
          <Card>
            <Card.Img variant="top" src={WaliaWithLemonCup} />
            <Card.Title className="cocktail-recipe-title">
              Walia Ibex Lemon
            </Card.Title>
            <ul className="recipes-list">
              <li>Walia Ibex</li>
              <li>Ice</li>
              <li>Lemon Juice</li>
            </ul>
          </Card>
        </div>
        <div className="col-md-4">
          <Card>
            <Card.Img variant="top" src={WaliaWithCola} />
            <Card.Title className="cocktail-recipe-title">
              Walia Ibex Cola
            </Card.Title>
            <ul className="recipes-list">
              <li>Walia Ibex</li>
              <li>Ice</li>
              <li>Cola</li>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  </div>
);

const BarDrinkSubPage = () => (
  <div>
    <Helmet>
      <meta property="og:title" content="Cocktail Recipes" />
      <meta
        property="og:description"
        content="Learn how you can make a cocktail with Walia Ibex Areke."
      />
      <meta
        name="twitter:title"
        content="Cocktail Recipes For Walia Ibex Areke"
      />
      <meta
        name="twitter:description"
        content="Learn how you can make a cocktail with Walia Ibex Areke."
      />
    </Helmet>
    <Jumbotron style={homeDrinkBackGroundImageStyle}>
      <h1>Cocktails</h1>
    </Jumbotron>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <Card>
            <Card.Img variant="top" src={WaliaIbexBitter} />
            <Card.Title className="cocktail-recipe-title">
              Walia Ibex Bitter
            </Card.Title>
            <ul className="recipes-list">
              <li>1.5 oz Wallia Ibex</li>
              <li>1.5 oz Cynar</li>
              <li>1 oz Cappelletti</li>
              <li>2 dashes Angostura Bitters</li>
              <li>Stirred</li>
              <li>Served over rock garnish with expressed orange peel</li>
              <br />
            </ul>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <Card.Img variant="top" src={WaliaYellowDrink} />
            <Card.Title className="cocktail-recipe-title">
              Walia Ibex Coconut{" "}
            </Card.Title>
            <ul className="recipes-list">
              <li>1.5 oz Wallia Ibex</li>
              <li>1 oz pineapple</li>
              <li>1 oz coconut</li>
              <li>2 syringes of the curry bitters</li>
              <li>¾ oz lemon</li>
              <li>Shaken</li>
              <li>
                Served over ice with mint garnish and shaved nutmeg on top
              </li>
            </ul>
          </Card>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6">
          <Card>
            <Card.Img variant="top" src={WaliaIbexSweetBitter} />
            <Card.Title className="cocktail-recipe-title">
              Walia Ibex Bitter and sweet
            </Card.Title>
            <ul className="recipes-list">
              <li>1.5 oz Wallia Ibex</li>
              <li>1.5 oz Cocchi Americano</li>
              <li>1 oz Giffard Banane liquor</li>
              <li>1 dash fee brothers black walnut bitters</li>
              <li>Stirred</li>
              <li>Served up in Nick and Norra Glass</li>
              <li>Express lemon peel with clip peel</li>
              <br />
              <br />
            </ul>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <Card.Img variant="top" src={WaliaIbexBlue} />
            <Card.Title className="cocktail-recipe-title">
              Walia Ibex Blue{" "}
            </Card.Title>
            <ul className="recipes-list">
              <li>1.5 oz Walia ibex</li>
              <li>1 oz simple syrup (1:1 sugar water)</li>
              <li>¾ oz Giffard blue curacao</li>
              <li>¾ lemon</li>
              <li>1 egg white</li>
              <li>Shake with ice first</li>
              <li>Strain into shaker tin and remove ice</li>
              <li>Shake with no ice</li>
              <li>Strain into glass</li>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  </div>
);

export default CocktailRecipe;

import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Telephone, Envelope } from "react-bootstrap-icons";

const ContactUs = ({ showContactUs = false, setShowContactUs }) => {
  return (
    <>
      <Modal
        show={showContactUs}
        onHide={() => setShowContactUs(false)}
        className="contact-us"
        centered="true"
        dialogClassName="contact-us-modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "rgba(192, 192, 192, 0.75)",
            display: "flex !important",
            justifyContent: "center",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <h3>Contact Us</h3>
        </Modal.Header>
        <Modal.Body>
          <address>
            {/* <h4 className="text-center">General Information</h4>
            For general questions email or call us. <br />
            <br /> */}
            <div className="contact-us-address-area">
              <a href="mailto:waliaibexareke@gmail.com">
                {" "}
                <b>
                  <Envelope size={45} /> &nbsp;&nbsp;&nbsp;
                  waliaibexareke@gmail.com
                </b>
              </a>
              <br />
              <a href="tel:3016848339">
                <b>
                  <Telephone size={45} /> &nbsp;&nbsp;&nbsp; (301) 684 - 8339
                </b>
              </a>
              <br />
              <br />
            </div>
          </address>
          {/* <hr
            style={{
              height: "3px",
              borderWidth: "0",
              color: "white",
              backgroundColor: "white",
            }}
          >
            
          </hr> */}
          {/* <address>
            <h4 className="text-center">Sales</h4>
            Licensed retailers or distributors email or call us. <br />
            <br />
            <div className="contact-us-address-area">
              <a href="mailto:sales@waliaibex.com">
                <Envelope size={30} /> &nbsp;&nbsp;&nbsp; sales@waliaibex.com
              </a>{" "}
              <br />
              <a href="tel:8042233937">
                <Telephone size={30} /> &nbsp;&nbsp;&nbsp; (804) 223 - 3937
              </a>
              <br />
            </div>
          </address> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

ContactUs.propTypes = {
  showContactUs: PropTypes.bool,
  setShowContactUs: PropTypes.func,
};

export default ContactUs;

import React from "react";
import { Carousel, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import waliaPouringMain from "../../images/WaliaPouringInTheGlass.jpg";
import WaliaCocktail from "../../images/WaliaCocktail.jpg";
import WaliaMainWallpaper from "../../images/WaliaMainWallpaperWin.png";

const ArekeCarousel = () => {
  return (
    <Carousel fade={true} slide={false} indicators={true} interval={null}>
      <Carousel.Item>
        <Image src={WaliaMainWallpaper} fluid />
        <Carousel.Caption>
          <h1>
            <NavLink to="/buy">BUY</NavLink>
          </h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={WaliaCocktail} fluid />
        <Carousel.Caption>
          <h1>
            <NavLink to="/buy">BUY</NavLink>
          </h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={waliaPouringMain} fluid />
        <Carousel.Caption>
          <h1>
            <NavLink to="/buy">BUY</NavLink>
          </h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default ArekeCarousel;

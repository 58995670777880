import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import Header from "./common/Header";
import PageNotFound from "./PageNotFound";
import Footer from "./common/Footer";
import AgeConfirmation from "./modals/AgeConfirmation";
import AccessDenied from "./accessDenied/AccessDenied";
import * as accessDenialTypes from "./accessDenied/DenialTypes";
import About from "./about/About";
import HomePageList from "./home/HomePageList";
import CocktailRecipe from "./recipes/CocktailRecipe";
import AvailableAreas from "./AvailableAreas/AvailableAreas";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";

// refactoring is badly needed for this component

function App() {
  const [cookies, setCookie] = useCookies([
    "showAgeConfirmModule",
    "isUserNotAllowedToViewPage",
  ]);

  const showAgeConfirmationCookie = () => {
    if (cookies.showAgeConfirmModule == null) {
      setCookie("showAgeConfirmModule", true, { path: "/" });
      return true;
    }
    return cookies.showAgeConfirmModule === "true";
  };

  const isIlligalAgeCookie = () => {
    if (cookies.isUserNotAllowedToViewPage == null) {
      setCookie("isUserNotAllowedToViewPage", false, { path: "/" });
      return false;
    }
    return cookies.isUserNotAllowedToViewPage === "true";
  };

  let showAConfCookie = showAgeConfirmationCookie();
  let isIllAgeCookie = isIlligalAgeCookie();
  let hasAccess = showAConfCookie === false && isIllAgeCookie === false;

  const expireDate = () => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  const handleShowAgeConfirmation = ({
    showConfirmation = true,
    isIlligalAge,
  }) => {
    setCookie("showAgeConfirmModule", showConfirmation, {
      path: "/",
      expires: expireDate(),
    });
    setCookie("isUserNotAllowedToViewPage", isIlligalAge, {
      path: "/",
      expires: expireDate(),
    });
  };

  const getAccessDenialType = () => {
    if (hasAccess === false && showAgeConfirmationCookie() === false) {
      return accessDenialTypes.ILLEGAL_AGE;
    } else {
      return accessDenialTypes.UNKNOWN;
    }
  };

  const AccessGrantedRoute = ({
    component: Component,
    isAccessGranted,
    location,
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAccessGranted === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/access-denied",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  AccessGrantedRoute.propTypes = {
    component: PropTypes.any,
    isAccessGranted: PropTypes.bool,
    location: PropTypes.any,
  };
  return (
    <div className="app-main">
      <Helmet>
        <meta property="og:site_name" content="Walia Ibex Areke" />
        <meta property="og:title" content="Walia Ibex Areke" />
        <meta
          property="og:description"
          content="Walia Ibex is an award winning premium Areke distilled from the finest ingredients. Our recipe has been enjoyed by people in East Africa for hundreds of years and we are excited to share it with you."
        />
        <meta
          property="og:image"
          content="https://www.waliaibex.com/47ad4bf9a03563140f87eec2e2bd4831.jpg"
        />
        <meta
          name="keywords"
          content="Walia Ibex, Areke, Drinks, Ethiopia, Spirits, Whiskey, traditional, east africa, premium"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ArekeIbex" />
        <meta
          name="twitter:image"
          content="https://www.waliaibex.com/47ad4bf9a03563140f87eec2e2bd4831.jpg"
        />
        <meta name="twitter:title" content="Walia Ibex Areke" />
        <meta
          name="twitter:description"
          content="Walia Ibex is an award winning premium Areke distilled from the finest ingredients. Our recipe has been enjoyed by people in East Africa for hundreds of years and we are excited to share it with you."
        />
      </Helmet>
      <AgeConfirmation
        showModal={showAgeConfirmationCookie()}
        handleShowAgeConfirmation={handleShowAgeConfirmation}
      />
      <Header />
      <div className="main-body">
        <Switch>
          <Route
            path="/access-denied"
            render={(props) => (
              <AccessDenied {...props} denialType={getAccessDenialType()} />
            )}
          />
          <AccessGrantedRoute
            isAccessGranted={hasAccess}
            exact
            path="/"
            component={HomePageList}
          />
          <AccessGrantedRoute
            isAccessGranted={hasAccess}
            path="/recipes"
            component={CocktailRecipe}
          />
          <AccessGrantedRoute
            isAccessGranted={hasAccess}
            path="/about"
            component={About}
          />
          <AccessGrantedRoute
            isAccessGranted={hasAccess}
            path="/buy"
            component={AvailableAreas}
          />
          <AccessGrantedRoute
            isAccessGranted={hasAccess}
            component={PageNotFound}
          />
        </Switch>
      </div>
      <Footer />
      <ToastContainer autoClose={3000} hideProgressBar />
    </div>
  );
}

export default App;

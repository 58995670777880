import React from "react";
import GoogleMap from "google-map-react";
import { ListGroup, Accordion, Card } from "react-bootstrap";
import * as Stores from "./Stores";
import * as Restaurants from "./Restaurants";
import { Helmet } from "react-helmet";
// import MapMarker from "./MapMarker";

const AvailableAreas = ({ google }) => {
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "500px",
  };

  const allAvailableStores = Stores.availableStores.concat(
    Restaurants.availableRestaurants
  );

  return (
    <div className="page-margin-with-header" style={{}}>
      <Helmet>
        <meta property="og:title" content="Buy" />
        <meta
          property="og:description"
          content="Buy our Walia Ibex Areke from select restaurants or spirit/wine shops."
        />
        <meta name="twitter:title" content="Buy - Walia Ibex Areke" />
        <meta
          name="twitter:description"
          content="Buy our Walia Ibex Areke from select restaurants or spirit/wine shops."
        />
      </Helmet>

      <h1 className="text-center">BUY</h1>

      <Accordion className="" defaultActiveKey="1">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="store-accordion-title"
          >
            Spirit/Wine Shops
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0" className="store-accordion-body">
            <Card.Body>
              <ListGroup className="available-areas-list" variant="flush">
                <div className="container">
                  {Stores.availableStores.map((store, index) => (
                    <ListGroup.Item
                      key={index}
                      variant="dark"
                      className="available-areas-list"
                    >
                      <div className="row">
                        <div className="col-md-3">{store.storeName} </div>
                        <div className="col-md-6">{store.address}</div>
                        <div className="col-md-3">{store.info}</div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </div>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className="store-accordion-title"
          >
            Restaurants
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1" className="store-accordion-body">
            <Card.Body>
              <ListGroup className="available-areas-list" variant="flush">
                <div className="container">
                  {Restaurants.availableRestaurants.map((restaurant, index) => (
                    <ListGroup.Item
                      variant="dark"
                      key={index}
                      className="available-areas-list"
                    >
                      <div className="row">
                        <div className="col-md-3">{restaurant.storeName} </div>
                        <div className="col-md-6">{restaurant.address}</div>
                        <div className="col-md-3">{restaurant.info}</div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </div>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <br />

      <div style={{ width: "100%", height: "500px", position: "relative" }}>
        <GoogleMap
          bootstrapURLKeys={{ key: "AIzaSyBAwDkA1mpVP0SzuWTu_jvacrnJ2C0i1o8" }}
          defaultCenter={{
            lat: 38.91105,
            lng: -77.07034,
          }}
          defaultZoom={11}
        >
          {allAvailableStores.map((store, index) => (
            <div
              key={index}
              text={store.storeName}
              name={store.storeName}
              position={store.position}
              lat={store.position.lat}
              lng={store.position.lng}
            >
              {store.marker}
            </div>
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default AvailableAreas;

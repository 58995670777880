import React from "react";
import WaliaIngredient from "../../images/WaliaIbexIngredient.jpg";

const backGroundImageStyle = {
  backgroundImage: `url(${WaliaIngredient})`,
  backgroundSize: "cover",
};

const HomePageItem2 = () => (
  <div
    className="home-page-item home-page-item-2"
    style={backGroundImageStyle}
  ></div>
);

export default HomePageItem2;

import React from "react";

const PageNotFound = () => {
  const pageNotFoundStyle = {
    marginTop: "10%",
    marginLeft: "30%",
    marginBottom: "30%",
  };

  return (
    <div className="page-not-found" style={pageNotFoundStyle}>
      <h1>Oops! Page not found.</h1>
    </div>
  );
};

export default PageNotFound;

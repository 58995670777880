import React from "react";
import { Jumbotron } from "react-bootstrap";
import Distillery from "../../images/Distillery.png";
import WaliaIngredient from "../../images/WaliaIbexIngredientOnly.png";
import GoldMedal from "../../images/2021_NYWSC_DoubleGold.png";
import { Helmet } from "react-helmet";

const backGroundImageStyle = {
  backgroundImage: `url(${Distillery})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  minHeight: "250px",
};

const About = () => (
  <div className="page-margin-with-header">
    <Helmet>
      <meta property="og:title" content="About" />
      <meta property="og:description" content="Learn more about our product." />
      <meta name="twitter:title" content="About Walia Ibex Areke" />
      <meta
        name="twitter:description"
        content="Learn more about our product."
      />
    </Helmet>
    <Jumbotron style={backGroundImageStyle}>
      <h1 style={{ color: "white", textAlign: "center" }}>Walia Ibex Areke</h1>
    </Jumbotron>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="float-right">
            <img
              className="about-us-side-img"
              src={GoldMedal}
              alt="Gold Medal"
            />
            <img
              className="about-us-side-img"
              src={WaliaIngredient}
              alt="Walia Ibex Areke Bottle"
            />
          </div>
          <div className="pull-left">
            <h3>
              Walia Ibex is an award-winning premium alcoholic beverage
              distilled from the finest Corn, Rye, Gesho (Rhamnus prinoides),and
              Malted barley. Our recipe has been enjoyed by people in East
              Africa for hundreds of years and we are excited to share it with
              you. The earthy, smokey flavor with the smooth finish makes it a
              perfect combination for a shot. Drink responsibly.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default About;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import ContactUs from "../modals/ContactUs";
import CookieConsent from "react-cookie-consent";

const Footer = () => {
  const [showContactUs, setShowContactUs] = useState(false);

  return (
    <div className="main-footer">
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
      />

      <div className="masterfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-3"></div>
            <div className="col-md-3 text-right">
              <a
                href="javascript:;"
                onClick={() => {
                  setShowContactUs(true);
                }}
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <SocialIcon
                url="https://www.facebook.com/waliaibex.areke/"
                bgColor="#bbbbbb"
                fgColor="#171717"
                target="_blank"
              />
              &nbsp;&nbsp;
              <SocialIcon
                url="https://www.instagram.com/waliaibexareke/"
                bgColor="#bbbbbb"
                fgColor="#171717"
                target="_blank"
              />
              &nbsp;&nbsp;
              <SocialIcon
                url="https://twitter.com/ArekeIbex"
                bgColor="#bbbbbb"
                fgColor="#171717"
                target="_blank"
              />
              &nbsp;&nbsp;
              <SocialIcon
                url="https://www.youtube.com/watch?v=3yG764lGoc4"
                bgColor="#bbbbbb"
                fgColor="#171717"
                target="_blank"
              />
            </div>
            <div className="col-3"></div>
            <div className="col-md-3 text-right">
              <NavLink to="/buy">Available Areas</NavLink>
            </div>
          </div>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-3"></div>
            <div className="col-md-3"></div>
          </div>
          <div className="row justify-content-center align-items-end">
            Copyright &copy; {new Date().getFullYear()} Walia Ibex. All Rights
            Reserved.
          </div>
        </div>
      </div>
      <CookieConsent
        buttonStyle={{
          background: "Silver",
          color: "black",
        }}
      >
        This website uses cookies to enhance your experience. By continuing to
        use this site, you agree to the storing of cookies on your device.
      </CookieConsent>
    </div>
  );
};

export default Footer;

import React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./components/App";
import "./index.css";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { CookiesProvider } from "react-cookie";

const history = createBrowserHistory();
const GA_TRACKING_ID = "UA-199762159-1";

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const initializeReactGA = () => {
  ReactGA.initialize(GA_TRACKING_ID, { debug: false });
  //ReactGA.pageview('');
};

initializeReactGA();

render(
  <Router history={history}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Router>,
  document.getElementById("app")
);

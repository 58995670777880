import React from "react";
import PropTypes from "prop-types";
import * as accessDenialTypes from "./DenialTypes";

const getDenialTitle = (denialType) => {
  switch (denialType) {
    case accessDenialTypes.ILLEGAL_AGE:
      return "Access Denied!";
    default:
      return "";
  }
};

const getDenialMessage = (denialType) => {
  switch (denialType) {
    case accessDenialTypes.ILLEGAL_AGE:
      return "Your age group is not allowed to view this page!";
    default:
      return "";
  }
};

const AccessDenied = ({ denialType }) => {
  return (
    <div className="access-denied-page">
      <h1>{getDenialTitle(denialType)}</h1>
      <br></br>
      <h3>{getDenialMessage(denialType)}</h3>
    </div>
  );
};

AccessDenied.propTypes = {
  denialType: PropTypes.string,
};

export default AccessDenied;

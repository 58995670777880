import React from "react";
import ArekeCarousel from "./ArekeCarousel";
import HomePageItem1 from "./HomePageItem1";
import HomePageItem2 from "./HomePageItem2";
import HomePageItem3 from "./HomePageItem3";
//import HomePageItem4 from "./HomePageItem4";

const HomePageList = () => {
  const componentsObjects = [
    { Component: ArekeCarousel },
    { Component: HomePageItem1 },
    { Component: HomePageItem2 },
    { Component: HomePageItem3 },
    // { Component: HomePageItem4 },
  ];

  return (
    <div className="main-home-page">
      {componentsObjects.map((componentObject, index) => (
        <componentObject.Component key={index} {...componentObject.props} />
      ))}
    </div>
  );
};

export default HomePageList;

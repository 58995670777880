import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import ButtonBoot from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

const AgeConfirmation = ({ showModal = true, handleShowAgeConfirmation }) => {
  const [show, setShow] = useState(showModal);

  const history = useHistory();

  const handleLegalAge = () => {
    handleShowAgeConfirmation({ showConfirmation: false, isIlligalAge: false });
    setShow(false);
    // This need to be refactored!!!
    history.replace("/");
    history.push("/");
  };

  const handleIllegalAge = () => {
    handleShowAgeConfirmation({ showConfirmation: false, isIlligalAge: true });
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleLegalAge}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="modal-backdrop"
        className="age-confirmation-modal"
        centered="true"
      >
        <Modal.Header>
          <Modal.Title>Are you over the age of 21?</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <ButtonBoot
            variant="dark"
            onClick={handleIllegalAge}
            className="age-confirmation-btn btn-block"
            size="large-modal-btn"
          >
            No
          </ButtonBoot>
          <ButtonBoot
            variant="dark"
            onClick={handleLegalAge}
            className="age-confirmation-btn btn-block"
            size="large-modal-btn"
          >
            Yes
          </ButtonBoot>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AgeConfirmation.propTypes = {
  showModal: PropTypes.bool,
  handleShowAgeConfirmation: PropTypes.func,
};

export default AgeConfirmation;

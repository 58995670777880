import React from "react";
import { Globe, GeoAltFill } from "react-bootstrap-icons";

const addressInfoStyle = {
  color: "white",
};

const geoAltStyle = {
  color: "red",
};

const blueLinkInfo = {
  color: "cornflowerBlue",
};

export const availableRestaurants = [
  {
    storeName: "Zene's Deli & Worldmarket",
    position: { lat: 38.99106, lng: -77.02373 },
    marker: (
      <a href="https://goo.gl/maps/KDu8TSAKFxEyVELx9" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/KDu8TSAKFxEyVELx9" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 8113 A Fenton St, Silver
        Spring, MD 20910
      </a>
    ),
    info: (
      <a href="https://zenesdeliandworldmarket.com/" style={blueLinkInfo}>
        <Globe size={30} />
      </a>
    ),
  },
  {
    storeName: "Tsehay Ethiopian Restaurant And Bar",
    position: { lat: 38.93487, lng: -77.02429 },
    marker: (
      <a href="https://g.page/tsehaydc?share" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://g.page/tsehaydc?share" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 3630 Georgia Ave NW,
        Washington, DC 20010
      </a>
    ),
    info: (
      <a href="https://www.tsehaydc.com/" style={blueLinkInfo}>
        <Globe size={30} />
      </a>
    ),
  },
  {
    storeName: "Nile Restaurant and Market",
    position: { lat: 38.98412, lng: -77.02642 },
    marker: (
      <a href="https://goo.gl/maps/enHf4UUL38Nb7Ucd6" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/enHf4UUL38Nb7Ucd6" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 7815 Georgia Ave NW,
        Washington, DC 20012
      </a>
    ),
    info: (
      <a href="https://www.nilerestaurantmarket.com/" style={blueLinkInfo}>
        <Globe size={30} />
      </a>
    ),
  },
  {
    storeName: "Zenebech Restaurant",
    position: { lat: 38.92115, lng: -77.04266 },
    marker: (
      <a href="https://goo.gl/maps/KQMjSLQvkjHF2vMo6" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/KQMjSLQvkjHF2vMo6" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 2420 18th St NW,
        Washington, DC 20009
      </a>
    ),
    info: (
      <a href="https://www.zenebechdc.com/" style={blueLinkInfo}>
        <Globe size={30} />
      </a>
    ),
  },
  {
    storeName: "Shalla Ethiopian Restaurant and Bar",
    position: { lat: 38.98994, lng: -77.02618 },
    marker: (
      <a href="https://goo.gl/maps/uzvWECJxBhp6s7AD7" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/uzvWECJxBhp6s7AD7" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 8081 Georgia Ave, Silver
        Spring, MD 20910
      </a>
    ),
    info: <div></div>,
  },
  {
    storeName: "Sheger Spring Cafe",
    position: { lat: 38.99133, lng: -77.02433 },
    marker: (
      <a href="https://goo.gl/maps/66SHj7STF3Y3y7xVA" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/66SHj7STF3Y3y7xVA" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 900 Silver Spring Ave B,
        Silver Spring, MD 20910
      </a>
    ),
    info: (
      <a href="http://shegerspringcafe.com/" style={blueLinkInfo}>
        <Globe size={30} />
      </a>
    ),
  },
  {
    storeName: "Langano Ethiopian Restaurant",
    position: { lat: 38.99306, lng: -77.02642 },
    marker: (
      <a href="https://goo.gl/maps/DnQgYzfxdi44V3m66" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/DnQgYzfxdi44V3m66" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} /> 8305 Georgia Ave, Silver
        Spring, MD 20910
      </a>
    ),
    info: (
      <a href="http://www.langanorestaurant.com/" style={blueLinkInfo}>
        <Globe size={30} />
      </a>
    ),
  },
  {
    storeName: "IBEX Ethiopian Restaurant and Lounge",
    position: { lat: 38.97481, lng: -76.99549 },
    marker: (
      <a href="https://goo.gl/maps/8tHmcteEdhV3Xpd8A" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
      </a>
    ),
    address: (
      <a href="https://goo.gl/maps/8tHmcteEdhV3Xpd8A" style={addressInfoStyle}>
        <GeoAltFill size={30} style={geoAltStyle} />
        6846 New Hampshire Ave, Takoma Park, MD 20912
      </a>
    ),
    info: (
      <a href="https://ibexethiopianrestaurant.com/" style={blueLinkInfo}>
        <Globe size={30} />
      </a>
    ),
  },
];

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/WaliaIbexLogo.png";
import { Navbar, Nav } from "react-bootstrap";

const Header = () => {
  const [navExpanded, setNavExpanded] = useState(false);

  const overrideToggle = (isCollapsed) => {
    setNavExpanded(isCollapsed);
  };

  const onLinkClick = () => {
    setNavExpanded(false);
  };

  return (
    <div className="main-header">
      <Navbar
        expand="xl"
        expanded={navExpanded}
        onToggle={overrideToggle}
        className="main-navbar navbar-expand-md order-md-0 mx-auto order-1"
        variant="dark"
        sticky="top"
      >
        <Navbar.Brand
          className="mr-auto order-0"
          to="/"
          as={Link}
          onClick={() => onLinkClick()}
        >
          <img
            className="header-logo d-inline-block align-top header-link"
            src={logo}
            alt="Walia Ibex Areke Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="header-navbar-toggle order-md-1 order-0"
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          bg="dark"
          variant="dark"
          className="header-link header-navbar-collapse"
        >
          <Nav className="ml-auto">
            <NavLink
              as={Link}
              to="/recipes"
              activeClassName="active-header-link"
              className="header-link"
              onClick={() => onLinkClick()}
            >
              COCKTAIL RECIPES
            </NavLink>
            <NavLink
              as={Link}
              to="/buy"
              activeClassName="active-header-link"
              className="header-link"
              onClick={() => onLinkClick()}
            >
              BUY
            </NavLink>
            <NavLink
              as={Link}
              to="/about"
              activeClassName="active-header-link"
              className="header-link"
              onClick={() => onLinkClick()}
            >
              ABOUT
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;

import React from "react";
import { Card, CardDeck } from "react-bootstrap";
import WaliaModelGivingBottle from "../../images/WaliaModelGivingBottle.jpg";
import WaliaModelHoldingBottle from "../../images/WaliaModelHoldingBottle.jpg";
import WaliaModelWithBottleOnHand from "../../images/WaliaModelWithBottleOnHand.jpg";
import WaliaIbexParty from "../../images/waliaIbexParty.jpg";
import WaliaWithLemon from "../../images/WaliaWithLemon.jpg";
import WaliaWithFire from "../../images/WaliaIbexWithFire.jpg";

const HomePageItem3 = () => (
  <div className="home-page-item home-page-item-3">
    <CardDeck>
      <Card className="bg-dark">
        <Card.Img src={WaliaIbexParty} variant="top" />
      </Card>
      <Card className="bg-dark">
        <Card.Img src={WaliaWithLemon} variant="top" />
      </Card>
      <Card className="bg-dark">
        <Card.Img src={WaliaModelWithBottleOnHand} variant="top" />
      </Card>
    </CardDeck>
    <div className="space-between-comps"></div>
    <CardDeck>
      <Card className="bg-dark">
        <Card.Img src={WaliaModelGivingBottle} variant="top" />
      </Card>
      <Card className="bg-dark">
        <Card.Img src={WaliaWithFire} variant="top" />
      </Card>
      <Card className="bg-dark">
        <Card.Img src={WaliaModelHoldingBottle} variant="top" />
      </Card>
    </CardDeck>
  </div>
);

export default HomePageItem3;
